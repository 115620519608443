import React from 'react';
import { Row, Col } from 'antd';

const TasksReceiveView = ({ item, isNoCap }) => {
  const { acceptAlarmUsers, repeatMode = [], alarmPushUsers, alarmAcceptUserNames } = item;
  return (
    <div className="tasks-receive-view info-view">
      <Row>
        {!isNoCap && (
          <Col span={24}>
            <div className="label">重复方式：</div>
            <div className="content">
              {Dict.map.weeks
                .filter(v => repeatMode.includes(v.value))
                .map(v => v.label)
                .join('、')}
            </div>
          </Col>
        )}
        <Col span={24} className="alarm-receive-user">
          <div className="label">告警接收人员：</div>
          <div className="content">
            {alarmAcceptUserNames
              ? alarmAcceptUserNames.join('、')
              : ((acceptAlarmUsers || alarmPushUsers).map((v, k) => (
                <span title={v.name || v} key={k}>
                  {v.name || v}
                </span>
              ))
              )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default TasksReceiveView;
