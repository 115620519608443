import React from 'react';
import { Row, Col } from 'antd';

const TaskRuleView = props => {
  const { item } = props;
  const { triggerDuration } = item;
  return (
    <div className="task-rule-view info-view">
      <Row>
        <Col span={24}>
          <div className="label">规则设置：</div>
          <div className="content">规则持续{Math.round(triggerDuration / (1000 * 60))}分钟触发提醒</div>
        </Col>
      </Row>
    </div>
  );
};

export default TaskRuleView;
