import React from 'react'
import MonitorTaskContainer from '../../TaskComponents/MonitorTaskContainer'

const OutSiderMonitorTask = () => (
  <MonitorTaskContainer
    taskType="101530"
    listModoleName="epidemicMoniterNotify"
    taskModuleName="epidemicMoniterTask"
    manageModuleName="epidemicMoniterTaskManage"
    moduleTitle="未戴口罩布控"
  />
)

export default OutSiderMonitorTask
