import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';

const BasicInfoView = props => {
  const { item, isNoCap } = props;
  const { startTime, endTime, executionType, executionDay, executionTime, description } = item;
  return (
    <div className="basic-info-view info-view">
      <Row>
        <Col span={24}>
          <div className="label">任务名称：</div>
          <div className="content">{item.name || item.taskName}</div>
        </Col>
        <Col span={24}>
          <div className="label">创建人：</div>
          <div className="content">{item.creatorName || item.userName}</div>
        </Col>
        {props.libType !== 3 && (
          <Col span={24}>
            <div className="label">创建时间：</div>
            <div className="content">{moment((item.createTime || item.createTimeStamp) * 1).format(Shared.format.dataTime)}</div>
          </Col>
        )}
        <Col span={24}>
          <div className="label">任务有效期：</div>
          <div className="content">{`${moment(startTime * 1).format(Shared.format.dataTime)} ~ ${moment(endTime * 1).format(Shared.format.dataTime)}`}</div>
        </Col>
        {!isNoCap && (
          <Col span={24}>
            <div className="label">任务执行时间：</div>
            <div className="content">{formatDayAndTime(executionType, executionDay, executionTime) || '-'}</div>
          </Col>
        )}
        <Col span={24}>
          <div className="label">任务说明：</div>
          <div className="content">{description}</div>
        </Col>
      </Row>
    </div>
  );
};

function formatDayAndTime(executionType, executionDay, executionTime) {
  if (executionType === undefined) {
    return '';
  }
  if (executionType === 0) {
    return '全天';
  }
  let dayText = Dict.map.weeks
    .filter(v => executionDay.includes(v.value))
    .map(v => v.label)
    .join('、');
  let timeText = '每天';
  executionTime.forEach((times, index) => {
    const start = Math.round(times[0] / (60 * 60 * 1000));
    const end = Math.round(times[1] / (60 * 60 * 1000));
    timeText += `${start < 9 ? '0' + start : start} ~ ${end < 9 ? '0' + end : end}点`;
    if (index !== executionTime.length - 1) {
      timeText += '，';
    }
  });
  return dayText + timeText;
}

export default BasicInfoView;
