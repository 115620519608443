import React from 'react'
import _ from 'lodash'
import { observer } from 'mobx-react'
import { message, Collapse, Button, Form } from 'antd'
import BasicInfoView from '../../../basicInfoView'
import TasksScopeView from '../../../tasksScopeView'
import TasksReceiveView from '../../../tasksReceiveView'
import FormBtn from '../../../formBtn'
import BasicInfoEdit from '../../../basicInfo'
import TasksScopeEdit from '../../../tasksScope'
import TasksReceiveEdit from '../../../tasksReceive'
import TaskRule from '../../../taskRule'
import TaskRuleView from '../../../taskRuleView'
import './index.less'

const IconFont = Loader.loadBaseComponent('IconFont')
const AuthComponent = Loader.loadBusinessComponent('AuthComponent')
const ConfirmComponent = Loader.loadBaseComponent('ConfirmComponent')
const Panel = Collapse.Panel

@Form.create()
@Decorator.businessProvider('device')
@observer
class MonitorTasksViewAndEdit extends React.Component {
  constructor(props) {
    super(props)
    this.infoModal = true
    this.state = {
      taskData: {
        taskType: +props.taskType // 黑名单
      }, //布控任务信息
      componentIsEdit: false, // 是否是编辑转态
      activeKey: ['1'], // 默认展开的信息
      initTaskData: {}, // 布控初始值，当编辑取消时使用
      errorShow: false, // 表单验证
      isShowModal: false, // 编辑二次确认弹框
      libType: props.libType
    }
  }

  componentDidMount() {
    this.dealItemData()
  }

  /**
   * @desc 对传入的说数据进行处理
   */
  dealItemData = () => {
    const { item = {} } = this.props
    let taskData = {
      id: item.id,
      name: item.name || item.taskName,
      startTime: item.startTime,
      endTime: item.endTime,
      description: item.description ? item.description : '', //兼容之前布控任务
      cids: item.cids, // 设备的详细信息，提交的时候进行处理
      acceptAlarmUser: item.acceptAlarmUser || item.alarmAcceptUsers, // 告警人员id[string]
      executionType: item.executionType,
      executionDay: item.executionDay,
      executionTime: item.executionTime,
      triggerDuration: item.triggerDuration
    }
    this.setState({
      taskData,
      initTaskData: _.cloneDeep(taskData)
    })
  }

  /**
   * @desc 手风琴头部定义
   */
  collapseHeader = index => {
    let title = ['基本信息', '任务范围', '任务规则', '任务接收']
    return (
      <div className="colla-header">
        <div className="left-title">
          <div className="colla-left">{title[index - 1]}</div>
        </div>
        <div className="right-icon" onClick={this.changeActive.bind(this, index + '')}>
          <IconFont type={~this.state.activeKey.indexOf(index + '') ? 'icon-S_Arrow_SmallUp' : 'icon-S_Arrow_SmallDown'} />
        </div>
      </div>
    )
  }

  /**
   * @desc 展开手风琴菜单
   */
  changeActive = index => {
    let activeKey = []
    if (~this.state.activeKey.indexOf(index)) {
      // 存在--剔除
      activeKey = _.without(this.state.activeKey, index)
    } else {
      // 不存在--追加
      activeKey = this.state.activeKey.concat(index)
    }
    this.setState({
      activeKey
    })
  }

  showEditComponent = () => {
    // 编辑转态下默认展开第一个组件
    let activeKey = ['1', '2', '3', '4']
    this.setState({
      activeKey,
      componentIsEdit: true
    })
  }

  /**
   * @desc 取消编辑
   */
  cancleSubmit = () => {
    this.setState({
      isShowModal: true
    })
  }

  /**
   * @desc 二次确认
   */
  handleCancel = () => {
    this.setState({
      isShowModal: false,
      activeKey: ['0']
    })
  }

  handleOk = () => {
    let initTaskData = this.state.initTaskData
    this.setState({
      taskData: initTaskData,
      componentIsEdit: false,
      isShowModal: false,
      activeKey: ['0']
    })
  }

  componentWillUnmount() {
    clearTimeout(this.setTimer)
  }

  /**
   * @desc 确认修改
   */
  toSubmit = () => {
    this.props.form.validateFields((err, data) => {
      const { taskData } = this.state
      const { taskType } = this.props
      if (err) {
        return
      }
      const { name, times, execution = {}, description, cids, repeatMode, acceptAlarmUser, triggerDuration } = data
      const { executionType, executionDay, executionTime } = execution
      const [startTime, endTime] = times
      const options = {
        id: taskData.id,
        type: taskType,
        name,
        executionType,
        executionDay,
        executionTime,
        description,
        cids,
        repeatMode,
        acceptAlarmUser,
        startTime: startTime * 1,
        endTime: endTime * 1,
        triggerDuration: triggerDuration * 1000 * 60
      }
      options.taskName = name
      options.tempId = '1571988598000'
      options.tempConfig = { cids }
      options.alarmAcceptUsers = acceptAlarmUser
      Service.controlCenter
        .updateTask2(options)
        .then(() => {
          message.success('修改成功')
          this.props.updateMonitorTasksList && this.props.updateMonitorTasksList()
          this.setState({ taskData: options, componentIsEdit: false })
        })
        .catch(err => {
          message.error(err.message || '修改失败')
        })
    })
  }

  /**
   * @desc 从编辑子组件修改的数据
   */
  changeTasksData = obj => {
    let taskData = Object.assign({}, this.state.taskData, obj)
    this.setState({ taskData })
  }

  render() {
    const { taskData, componentIsEdit, libType, activeKey, errorShow, isShowModal } = this.state
    const { item, actionName, taskType } = this.props
    const isNoCap = taskType === '101530'
    return (
      <div className="monitor-task-view-edit-container">
        <div className="taks-full-top">
          <div className="task-top">
            <span title={taskData.name || taskData.taskName} className="title">
              {taskData.name || taskData.taskName}
            </span>
            {!componentIsEdit && (
              <AuthComponent actionName={actionName}>
                <Button onClick={this.showEditComponent}>
                  <IconFont type="icon-S_Edit_Edit" />
                  编辑
                </Button>
              </AuthComponent>
            )}
          </div>
        </div>
        <div className="view-edit-box">
          <Form autocomplete="off">
            <div className="task-form-box">
              <Collapse bordered={false} activeKey={activeKey}>
                <Panel header={this.collapseHeader(1)} key="1">
                  {componentIsEdit ? (
                    <div>
                      <BasicInfoEdit
                        itemDate={taskData}
                        changeTasksData={this.changeTasksData}
                        form={this.props.form}
                        libType={libType}
                        isNoCap={isNoCap}
                      />
                    </div>
                  ) : (
                    <BasicInfoView item={item} libType={libType} isNoCap={isNoCap} />
                  )}
                </Panel>

                <Panel header={this.collapseHeader(2)} key="2" className="area-camera-list">
                  {componentIsEdit ? (
                    <div>
                      <TasksScopeEdit errorShow={errorShow} changeTasksData={this.changeTasksData} itemDate={taskData} form={this.props.form} />
                    </div>
                  ) : (
                    <TasksScopeView item={item} />
                  )}
                </Panel>
                {!isNoCap && (
                  <Panel header={this.collapseHeader(3)} key="3">
                    {componentIsEdit ? (
                      <div>
                        <TaskRule errorShow={errorShow} changeTasksData={this.changeTasksData} itemDate={taskData} form={this.props.form} />
                      </div>
                    ) : (
                      <TaskRuleView item={item} />
                    )}
                  </Panel>
                )}
                <Panel header={this.collapseHeader(4)} key="4">
                  {componentIsEdit ? (
                    <div>
                      <TasksReceiveEdit
                        isNoCap={isNoCap}
                        itemDate={taskData}
                        errorShow={errorShow}
                        changeTasksData={this.changeTasksData}
                        form={this.props.form}
                        libType={libType}
                      />
                    </div>
                  ) : (
                    <TasksReceiveView item={item} libType={libType} isNoCap={isNoCap} />
                  )}
                </Panel>
              </Collapse>
              {componentIsEdit && <FormBtn cancleSubmit={this.cancleSubmit} toSubmit={this.toSubmit} />}
            </div>
          </Form>
        </div>
        <ConfirmComponent
          title="提示"
          visible={isShowModal}
          onCancel={this.handleCancel}
          onOk={this.handleOk}
          className="monitor-tasks-model"
          img="warning"
        >
          <div className="edit-model">
            <div className="model-content">确定要取消编辑吗？已经编辑的数据将不会保存。</div>
          </div>
        </ConfirmComponent>
      </div>
    )
  }
}

export default MonitorTasksViewAndEdit
