import React from 'react';
import { message } from 'antd';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import TasksList from '../../tasksList';

// 加载组件
@withRouter
@Decorator.businessProvider('MonitorTask')
@observer
class monitorTasksList extends React.Component {
  constructor(props) {
    super(props);
    this.initTime = true;
    this.state = {
      name: '', //当前搜索条件（布控任务)
      taskTypes: props.taskTypes, //101501-黑名单 101502-未知人员布控 101503-魅影 101504-一体机
      list: [], // 布控任务列表
      isShowModal: false // 删除任务弹框确认
    };
  }

  componentWillMount() {
    const { changeLoadingState, setItem, refDom } = this.props;
    refDom && refDom(this);
    this.getTaskList().then(result => {
      let res = result.list || [];
      if (res.length) {
        // 设置第一个为选中状态
        let item = res[0] || {};
        if (item.id) {
          this.setItemById(item.id);
        }
      } else {
        changeLoadingState && changeLoadingState(false);
        setItem && setItem();
      }
    });
  }

  /**
   * @desc 根据条件查询任务列表
   */
  getTaskList = async () => {
    const { taskTypes } = this.props;
    const { name } = this.state;
    const status = ['0', '1', '2', '3'];
    const options = {
      types: taskTypes,
      keywords: name,
      limit: 1000,
      offset: 0,
      status,
      source: 1
    };
    options.taskName = name;
    options.type = 0;
    options.taskTypes = taskTypes;
    delete options.status;
    delete options.types;
    delete options.keywords;

    const res = await Service.monitorTask.getTasks(options);
    let { changeLoadingState } = this.props;
    // 将data数据合到mobx数据  needUnhandledAlarmCount(后台优化，写死字段)
    this.initTime = false;
    if (!res) {
      message.error('布控任务列表查询失败');
      changeLoadingState && changeLoadingState(false);
      return false;
    }
    const list = Array.isArray(res.data.list) ? res.data.list : [];
    await this.setState({ list });
    return res.data;
  };

  /**
   * @desc 通过id拿数据详情，并设置当前选中的任务
   * @param {string} id 布控任务id
   */
  setItemById = id => {
    const { changeLoadingState, setItem, changeLibId } = this.props;
    changeLoadingState && changeLoadingState(true); // 设置loading状态
    // prettier-ignore
    return Service.monitorTask.getTaskById(id)
      .then(res => {
        changeLoadingState && changeLoadingState(false);
        setItem && setItem(res.data);
        changeLibId && changeLibId(id);
      })
      .catch(err => {
        message.error('布控任务详情查询失败');
        changeLoadingState && changeLoadingState(false);
      });
  };

  /**
   * @desc 暂停/开启任务
   * @param {object} e 事件对象-阻止冒泡
   * @param {object} item 单个布控任务信息
   */
  onStartPauseBtnClick = (e, item) => {
    e.stopPropagation();
    if (Date.now() > item.endTime) {
      return message.info('请修改有效时间');
    }
    const { taskTypes } = this.props;
    Service.monitorTask
      .updateTaskStop(
        { taskId: item.id, isStop: item.status === 1 ? 1 : 0 },
        {
          taskType: taskTypes[0] + '',
          taskName: item.name
        }
      )
      .then(response => {
        if (response.code === 0) {
          this.getTaskList(); // 重新刷新列表
          message.success('任务操作成功');
        } else {
          message.error('任务操作失败');
        }
      });
  };

  /**
   * @desc 根据名称搜索布控任务
   * @param {string} value 布控任务名称
   */
  changeVal = value => {
    this.setState({ name: value });
    this.getTaskList({ name: value }).then(res => {
      const list = res.list || [];
      let item = list[0] ? list[0] : {};
      if (item.id) {
        this.setItemById(item.id);
      } else {
        this.props.setItem && this.props.setItem({});
      }
    });
  };

  /**
   * @desc 删除布控任务弹框
   * @param {object} obj
   * @param {string} id 布控任务id
   * @param {boolean} isActive 是否删除的是当前选中的布控任务
   * @param {string} name 布控名称
   */
  delTasksModal = ({ id, isActive, name }) => {
    this.delLibName = name;
    this.isActive = isActive;
    this.delId = id;
    this.setState({
      isShowModal: true
    });
  };

  /**
   * @desc 取消删除
   */
  handleCancel = () => {
    this.delLibName = '';
    this.isActive = '';
    this.delId = '';
    this.setState({
      isShowModal: false
    });
  };

  /**
   * @desc 确认删除
   */
  handleOk = () => {
    const { setItem, taskTypes } = this.props;
    const flag = taskTypes[0] == '101530';
    // tip: 这里无帽进入后厨是调用的车辆的删除接口
    Service.monitorTask
      .deleteVehiclesTask({
        id: this.delId,
        taskType: taskTypes[0],
        taskName: this.delLibName
      })
      .then(res => {
        if (res.code === 0) {
          message.success('任务删除成功');
          this.setState({
            isShowModal: false
          });
          this.getTaskList({}).then(res => {
            let list = res.list || [];
            if (this.isActive) {
              let item = list[0] ? list[0] : {};
              if (item.id) {
                this.setItemById(item.id);
              } else {
                setItem && setItem({});
              }
            }
          });
        }
      })
      .catch(err => {
        this.setState({
          isShowModal: false
        });
        message.warn(err.message || '任务删除失败');
      });
  };

  render() {
    const { list, isShowModal } = this.state;
    const { activeItem, libType, moduleName } = this.props;
    return (
      <TasksList
        isShowModal={isShowModal}
        list={list}
        item={activeItem}
        libType={libType}
        handleCancel={this.handleCancel}
        handleOk={this.handleOk}
        delLibName={this.delLibName}
        changeVal={this.changeVal}
        setItemById={this.setItemById}
        delTasksModal={this.delTasksModal}
        onStartPauseBtnClick={this.onStartPauseBtnClick}
        moduleName={moduleName}
        initTime={this.initTime}
      />
    );
  }
}

export default monitorTasksList;
